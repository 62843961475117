body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media all and (max-width:800px) {
	.intro h1 {
		font-size: 36px !important;
	}
  #menu a.navbar-brand {
    font-size: 15px;
  }
}

.dhm-icon {
  font-size: 34px !important;
}

.required {
  color: red;
}