.score-header {
  position: fixed;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.score-table {
  background-color: #FB8C00;
  color: white;
  max-width: 500px
}

.score-tournament {
  border-bottom: 0;
  vertical-align: middle;
  font-weight: bold;
  text-align: center;
}

.score-captains {
  width: 35%;
  padding: 8px 0;
  text-align: center;
}

.score-teamname {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.score-captainName {
  font-weight: normal;
  text-transform: uppercase;
  font-size: 10px;
  display: inline-block;
  margin: 4px 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.score-value {
  font-size: 28px;
}

.score-valueTable {
  font-size: 13px;
  margin-top: 81px;
  max-width: 500px;
}

.poker-results-button-container {
  margin-top: 6px;
}

.poker-results-button {
  background: none;
  border: none;
  text-transform: uppercase;
  font-size: 12px;
  background-color: #E65100;
  color: #fff;
  border-radius: 5px;
  padding: 3px 8px;
}

.dialog-wrapper {
  background-color: rgba(18, 18, 18, 0.75);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
}

.dialog-container {
  background: white;
  margin: 12px;
  padding: 12px;
  width: auto;
  text-align: left;
  color: #111111;
  height: 750px;
  max-height: 750px;
  overflow: auto;
}

.dialog-container h3 {
  margin: 0;
  padding: 0;
  position: relative;
}

.dialog-exit {
  position: absolute;
  top: 0;
  right: 0;
  color: black;
  padding: 2px 8px;
  border: 0;
  background: none;
}

.poker-hand-table {
  font-weight: normal;
  margin-top: 16px;
  width: 100%;
}

.poker-hand-row {
  display: block;
  width: 100%;
  border-bottom: 1px solid #efefef;
  padding: 10px 0;
}

.poker-name {
  display: inline-block;
  width: 55%;
}

.poker-hand-image {
  display: inline-block;
  width: 45%;
  text-align: center;
}

.poker-hand-image img {
  height: 50px;
}
